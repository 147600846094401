import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { FacebookPage } from '~core/models/facebook-page.model'
import { InstagramAccount } from '~core/models/instagram-account.model'
import { JsonResponse } from '~core/models/json-response.model'
import { ApiService } from '~core/services'
import { environment } from '~env'
import { LinkedinAccount, LinkedinAccountRequest } from '~features/integrations/models/linkedin-account.model'
import { ConnectableLinkedinOrganization } from '~features/integrations/models/linkedin-organization.model'

@Injectable({
  providedIn: 'root',
})
export class LinkedinAccountService {
  private baseUrl = '/v1.1/linkedin-accounts'

  constructor(private apiService: ApiService) {}

  delete = (id: string): Observable<JsonResponse<{ _id: string }>> => {
    return this.apiService.delete(`${this.baseUrl}/${id}`)
  }

  get = (id: string): Observable<JsonResponse<LinkedinAccount>> => {
    return this.apiService.get(`${this.baseUrl}/${id}`)
  }

  getAll = (): Observable<JsonResponse<LinkedinAccount[]>> => {
    return this.apiService.get(this.baseUrl)
  }

  getOrganizations = (id: string): Observable<JsonResponse<ConnectableLinkedinOrganization[]>> => {
    return this.apiService.get(`${this.baseUrl}/${id}/organizations`)
  }

  makePrimary = (id: string): Observable<any> => {
    return this.apiService.post(`${this.baseUrl}/${id}/make-primary`)
  }

  refreshToken = (payload: LinkedinAccountRequest): Observable<any> => {
    const { id, ...request } = payload
    return this.apiService.patch(`${this.baseUrl}/${id}/refresh-token`, request)
  }

  store(payload: LinkedinAccountRequest): Observable<JsonResponse<LinkedinAccount>> {
    return this.apiService.post(this.baseUrl, payload)
  }
}
