import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { FacebookPage } from '~core/models/facebook-page.model'
import { InstagramAccount } from '~core/models/instagram-account.model'
import { JsonResponse } from '~core/models/json-response.model'
import { ApiService } from '~core/services/api.service'
import { environment } from '~env'

import { FacebookAccount, FacebookAccountRequest } from '../models/facebook-account.model'

@Injectable({
  providedIn: 'root',
})
export class FacebookAccountService {
  private baseUrl = '/v1.1/facebook-accounts'

  constructor(private apiService: ApiService) {}

  checkScopes = (id: string, scopes = environment.facebookScopes): Observable<JsonResponse<{ message: string }>> => {
    return this.apiService.post(`${this.baseUrl}/${id}`, { scopes })
  }

  delete = (id: string): Observable<JsonResponse<{ _id: string }>> => {
    return this.apiService.delete(`${this.baseUrl}/${id}`)
  }

  get = (id: string): Observable<JsonResponse<FacebookAccount>> => {
    return this.apiService.get(`${this.baseUrl}/${id}`)
  }

  getAll = (): Observable<JsonResponse<FacebookAccount[]>> => {
    return this.apiService.get(this.baseUrl)
  }

  getFacebookAdAccounts = (id: string): Observable<JsonResponse<any[]>> => {
    return this.apiService.get(`${this.baseUrl}/${id}/facebook-ad-accounts`)
  }

  getFacebookPages = (id: string): Observable<JsonResponse<FacebookPage[]>> => {
    return this.apiService.get(`${this.baseUrl}/${id}/facebook-pages`)
  }

  getInstagramAccounts = (id: string): Observable<JsonResponse<InstagramAccount[]>> => {
    return this.apiService.get(`${this.baseUrl}/${id}/instagram-accounts`)
  }

  makePrimary = (id: string): Observable<any> => {
    return this.apiService.post(`${this.baseUrl}/${id}/make-primary`)
  }

  refreshToken = (payload: FacebookAccountRequest): Observable<any> => {
    const { id, ...request } = payload
    return this.apiService.put(`${this.baseUrl}/${id}/refresh-token`, request)
  }

  store(payload: FacebookAccountRequest): Observable<JsonResponse<FacebookAccount>> {
    return this.apiService.post(this.baseUrl, payload)
  }
}
